<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="京东拉新设置"
        >
          <!-- <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template> -->
        </a-page-header>
      </div>

      <a-form
        :form="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="handleSubmit"
        style="margin-top: 24px"
      >
        <a-form-item label="上传文件：">
          <a-input
            v-decorator="[
              'file',
              {
                rules: [{ required: false, message: '请选择客服图片!' }],
              },
            ]"
            type="hidden"
          ></a-input>
          <a-upload
            name="file"
            list-type="picture"
            class="upload-list-inline"
            :fileList="fileList"
            :customRequest="changeImg"
            @change="getchangeImg"
            :remove="removeImg"
            :beforeUpload="handleBeforeUpload"
          >
            <a-button :disabled="!uploadBtn">
              <a-icon type="upload" /> 上传文件
            </a-button>
          </a-upload>
          <a @click="getExcel">示例excel文件</a>
        </a-form-item>
        <!-- <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit"> 确认 </a-button>
        </a-form-item> -->
      </a-form>
      <a-row>使用说明： </a-row>
      <a-row>下载示例文件 </a-row>
      <a-row>在示例文件中填写需要的内容 </a-row>
      <a-row>上传填写完的文件</a-row>
      <a-row>等待上传完成（时间可能比较长，不要频繁操作）</a-row>
      <a-row style="color:#f00">注：上传成功后将替换H5页面商品及规则，请谨慎操作</a-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      uploadBtn: true,
      fileList: [],
      canUpload: "",
    };
  },
  mounted() {},
  methods: {
    //获取之前的设置
    getOne(){
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/admin/config/getOne/jdreward20211106")
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            console.log(res)
          // console.log(res.data.data.file);
          // window.open(res.data.data.file);
          }
        });
    },
    //下载示例文件
    getExcel() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/admin/activitySourceMaterial/sampleFile")
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          // if (that.$code(res)) {
          console.log(res.data.data.file);
          window.open(res.data.data.file);
          // }
        });
    },
    //上传文件
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .post("/admin/activitySourceMaterial/upload", formData)
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          console.log(res)
          if (that.$code(res)) {
              that.$message.success("设置成功");
          that.uploadBtn = true;
          that.form.setFieldsValue({
            customer_service_url: "",
          });
          that.fileList = [];
          }
          // const link = document.createElement("a");
          // var blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          // link.style.display = "none";
          // link.href = URL.createObjectURL(blob);

          // // link.download = res.headers['content-disposition'] //下载后文件名
          // link.download = "京东拉新设置"; //下载的文件名
          // document.body.appendChild(link);
          // link.click();
          // URL.revokeObjectURL(link);
          // document.body.removeChild(link);
        });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("sheet") < 0) {
        this.$message.error("仅支持上传excel文件");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.form.setFieldsValue({
            customer_service_url: "",
          });
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          values.customer_service_id = this.customer_service_id;
          var data = values;
          that.axios.post("/admin/customerService/update", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  height: auto;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>